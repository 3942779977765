import PageHeader from '@/components/PageHeader'
import Adsearch from '@/components/Adsearch'
import tableMixin from '@/minxin/table-minxin'
import printJS from 'print-js'
const Index = {
  name: 'order-processing',
  mixins: [tableMixin],
  components: {
    PageHeader,
    Adsearch
  },
  data() {
    return {
      isDefaultStock: false,
      ckForm: {
        KeyWords: '',
        PageIndex: 1,
        PageSize: 15
      },
      Cklist: [],
      reoson: '',
      printDeliveryVisible: false,
      pritInfo: {
        tableList: []
      },
      tempArr: [], // 暂存数组
      isHideList: false, // 是否隐藏已完成
      price: 0, // 调整单价
      yuComeList: false,
      form: {}, // 头部数据
      Precord: [], // 预入库记录
      isSetList: false,
      operationRecord: [], // 操作记录
      setSelectList: false,
      selectedMaterial: [], // 选中物料
      setSelecNumtList: false,
      receipt: false, // 生成预入库单
      delRemake: '', // 删除原因
      tRemake: '', // 调价原因
      remake: '' // 创建备注
    }
  },
  mounted() {
    console.log(this.$route.params)
    this.GetPurchaseOrderInfos()
    this.getCK()
  },
  beforeUpdate() {
    this.$nextTick(() => { // 在数据加载完，重新渲染表格
      this.$refs.multipleTable.doLayout()
    })
  },
  watch: {
    selectedMaterial(a) {
      a.forEach(item => {
        this.$set(item, 'price', item.PurchaseSapPrice)
      })
    },
    price(a) {
      this.selectedMaterial.forEach(item => {
        this.$set(item, 'price', a)
      })
    },
    isHideList(a) {
      const temp = []
      Object.assign(temp, this.tableData)
      console.log(temp)
      if (a) {
        // 筛选
        this.tableData = this.tableData.filter(item => item.Status !== 1)
        console.log(this.tableData)
        return
      }
      this.GetPurchaseOrderInfos()
      // if (a) {
      //   // 隐藏已完成
      //   const arr = []
      //   this.tableData.forEach(item => {
      //     if (!item.IsShow) {
      //       arr.push(item)
      //     }
      //   })
      //   return this.tableData = arr
      // }
      // return this.tableData = this.tempArr
    }
  },
  computed: {
    // 不含税总金额
    Allprice() {
      let num = 0
      this.selectedMaterial.forEach(item => {
        num += item.Amount
      })
      return num.toFixed(3)
    },
    // 含税总金额
    AllToaprice() {
      let num = 0
      this.selectedMaterial.forEach(item => {
        num += item.TaxAmount
      })
      return num.toFixed(3)
    },
    afetrAllprice() {
    // 调价后不含税总金额
      let num = 0
      this.selectedMaterial.forEach(item => {
        num += (item.price * item.PurchaseSapNum)
      })
      return num.toFixed(3)
    },
    // 调价后含税总金额
    afterAllToaprice() {
      let num = 0
      this.selectedMaterial.forEach(item => {
        num += (item.price * (1 + item.TaxRate)) * item.PurchaseSapNum
      })
      return num.toFixed(3)
    },
    ALLtoal() {
      let num = 0
      this.selectedMaterial.forEach(item => {
        // eslint-disable-next-line no-const-assign
        num += item.NetPrice * (1 + item.TaxRate) * item.PurchaseSapNum
      })
      return num
    }
  },
  methods: {
    selectAll(e) {
      console.log(e) // MaterialLotNo
      this.selectedMaterial = e
    },
    select(row) {
      this.selectedMaterial = row
    },
    // 复制到页面上实现获取数据逻辑
    async getTableDataAsync(page, pageSize) {
      // this.formSearch.PageIndex = 1
      // this.formSearch.PageSize = this.tablePageSize
      // const response = await this.$api.GetPurchaseOrderList(this.formSearch)
      // this.tableData = response.Message
      // this.tableTotalSize = response.Total
      // console.log(response)
      const Message = []
      return Message
    },
    // 修改选中物料价格
    selectGoods() {
      if (this.selectedMaterial.length <= 0) return this.$message.warning('请选择你要修改价格的物料')
      this.reoson = ''
      this.$api.GetPurchareOrderOperationLog({
        OrderPurchaseSapID: this.form.OrderPurchaseSapID,
        SelectType: 0, // 0：修改采购价格记录，1：采购单明晰操作记录
        PORowNum: 0
      }).then(res => {
        console.log('dassddasdsa', res)
        res.Message.forEach(item => {
          this.reoson += `${item.WriteTime} ${item.NickName} ${item.Remark};  &nbsp;  &nbsp;`
        })
      })
      this.setSelectList = true
    },
    // 修改选中物料数量
    setSelecNumtListShow() {
      if (this.selectedMaterial.length <= 0) return this.$message.warning('请选择你要修改的物料')
      this.setSelecNumtList = true
    },
    // 提交修改数量
    setSelecNumtListSubmit() {
      console.log('asdas', this.selectedMaterial)
      if (!this.delRemake) return this.$message.error('删除原因不可为空')
      // const OrderPurchaseSapDetailsIDX = ''
      const DeleteDetailsContent = []
      const result = this.selectedMaterial.filter(item => item.num * 1 >= 0)
      if (result.length <= 0) return this.$message.warning('请填写调整后的数量')
      /**
       * {
  POID:"采购单号",

  Remark:"备注",

  Details:[{
    IDX:12312, // 采购单明细id
    PORowNum:'10', // 明细行号
    DeleteNum:123 // 修改后的数量
  }]
}
       */
      // this.selectedMaterial.forEach(element => {
      //   console.log(element)
      //   OrderPurchaseSapDetailsIDX += `${element.OrderPurchaseSapDetailsIDX},`
      //   DeleteDetailsContent += `${element.OrderPurchaseSapDetailsIDX}#${element.PORowNum}#${element.num}|`
      // })
      this.selectedMaterial.forEach(element => {
        DeleteDetailsContent.push({
          IDX: element.OrderPurchaseSapDetailsIDX, // 采购单明细id
          PORowNum: element.PORowNum, // 明细行号
          DeleteNum: element.num // 修改后的数量
        })
        // DeleteDetailsContent += `${element.OrderPurchaseSapDetailsIDX}#${element.PORowNum}#${element.num}|`
      })
      this.$api.DeleteOrderPurchaseSapDetails({
        POID: this.form.OrderPurchaseSapID,
        // OrderPurchaseSapDetailsIDX: OrderPurchaseSapDetailsIDX,
        Remark: this.delRemake,
        Details: DeleteDetailsContent
      }).then(res => {
        console.log(res)
        this.setSelecNumtList = false
        if (res.RetCode === '0') {
          this.GetPurchaseOrderInfos()
          return this.$message.success('请求成功')
        }
        return this.$message.error(res.RetMsg)
      })
    },
    // 提交 调价
    tSubmit() {
      if (!this.tRemake) return this.$message.warning('调价原因不可为空')
      let UpdatePriceData = ''
      this.selectedMaterial.forEach(element => {
        console.log(element)
        UpdatePriceData += `${element.PORowNum}#${element.price}|`
      })
      this.$api.UpdatePurchareOrderPrice({
        OrderPurchaseSapID: this.form.OrderPurchaseSapID,
        Remark: this.tRemake,
        UpdatePriceData: UpdatePriceData
      }).then(res => {
        console.log(res)
        this.setSelectList = false
        if (res.RetCode === '0') {
          this.GetPurchaseOrderInfos()
          return this.$message.success('请求成功')
        }
        return this.$message.error(res.RetMsg)
      })
    },
    // 导出
    OrderPurchaseExportByIDX() {
      // if (this.selectedMaterial.length <= 0) return this.$message.error('没有任何选中')
      let OrderPurchaseSapDetailsIDX = ''
      this.selectedMaterial.forEach(element => {
        OrderPurchaseSapDetailsIDX += `${element.OrderPurchaseSapDetailsIDX},`
      })
      this.$api.OrderPurchaseExportByIDX({
        IDX: OrderPurchaseSapDetailsIDX
      }).then(res => {
        console.log(res)
        if (res.RetCode === '0' && res.Message) {
          return this.$minCommon.downloadFile(res.Message)
        }
        return this.$message.error('错误： ' + res.Message)
      })
    },
    // 隐藏已完成
    isHide() {
      this.isHideList = !this.isHideList
    },
    // 生成库存
    showreceipt() {
      if (this.selectedMaterial.length <= 0) return this.$message.warning('请选择你要操作的物料')
      this.receipt = true
    },
    // 表格居中
    cellstyle() {
      return 'text-align: center;'
    },
    // 打印
    print() {
      this.printDeliveryVisible = true
      this.pritInfo.tableList = this.tableData
      // this.printHTML('testTable')
      // printJS({
      //   printable: 'testTable', // DOM id
      //   type: 'html',
      //   scanStyles: false
      // })
    },
    printDelivery() {
      this.printHTML('testTable')
    },
    printHTML(id) {
      const html = document.querySelector('#' + id).innerHTML
      // 新建一个 DOM
      const div = document.createElement('div')
      const printDOMID = 'printDOMElement'
      div.id = printDOMID
      div.innerHTML = html
      document.querySelector('body').appendChild(div)
      // document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children[0].children.re
      // console.log(document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children)
      // 删除最后一项
      // const tr_arr = document.getElementById('printDOMElement').childNodes[2].children[0].children[1].children
      // for (const i in tr_arr) {
      //   console.log(tr_arr[i].children)
      //   if (tr_arr[i].children) {
      //     tr_arr[i].removeChild(tr_arr[i].children[tr_arr[i].children.length - 1])
      //   }
      // }
      // 提取第一个表格的内容 即表头
      const ths = div.querySelectorAll('.el-table__header-wrapper th')
      const ThsTextArry = []
      for (let i = 0, len = ths.length; i < len; i++) {
        if (ths[i].innerText !== '操作') ThsTextArry.push(ths[i].innerText)
      }

      // 删除多余的表头
      div.querySelector('.hidden-columns').remove()
      // 第一个表格的内容提取出来后已经没用了 删掉
      div.querySelector('.el-table__header-wrapper').remove()

      // 将第一个表格的内容插入到第二个表格
      let newHTML = '<tr style="text-align: center; border:1px solid #ccc" >'
      for (let i = 0, len = ThsTextArry.length; i < len; i++) {
        newHTML += '<td style="text-align: center; font-weight: bold; padding:5px 0;border:1px solid #ccc ">' + ThsTextArry[i] + '</td>'
      }
      newHTML += '</tr>'
      div.querySelector('.el-table__body-wrapper table').insertAdjacentHTML('afterbegin', newHTML)
      // 将新的 DIV 添加到页面 打印后再删掉
      // document.querySelector('body').appendChild(div)

      printJS({
        printable: printDOMID,
        type: 'html',
        scanStyles: false,
        style: '.mid_info {display:flex} .el-table__body-wrapper table {border-collapse:collapse} .el-table__body-wrapper table td{ border:1px solid #ccc; text-align: center }' // 表格样式
      })
      div.remove()
    },
    // 获取仓库
    async getCK() {
      const response = await this.$api.GetStockCodeList(this.ckForm)
      this.Cklist = response.Message
      console.log('仓库，', response)
    },
    // 默认仓库被改变
    isChangeStock(e) {
      this.isDefaultStock = true
      console.log(e)
    },
    // 创建入库单
    CreateASNReceiveOrder() {
      if (this.selectedMaterial.length <= 0) return this.$message.warning('物料为空')
      if (!this.remake && this.isDefaultStock) return this.$message.info('默认仓库改变,备注为必填')
      const arr = this.selectedMaterial.filter(item => !item.PreWarehousingNum)
      if (arr.length > 0) return this.$message.warning('预入库数量不能为空')
      const createASNReceiveOrders = []
      this.selectedMaterial.forEach(element => {
        console.log(element)
        createASNReceiveOrders.push({
          RowNum: element.PORowNum,
          MaterialRefCompanyID: element.MaterialRefCompanyID,
          PreWarehousingNum: element.PreWarehousingNum,
          StockID: element.StockID,
          Expiredate: element.Expiredate,
          InvCode: element.InvCode,
          LotNo: element.LotNo
        })
      })
      console.log(createASNReceiveOrders)
      this.$api.CreateASNReceiveOrder({
        OrderPurchaseSapID: this.form.OrderPurchaseSapID,
        Remark: this.remake,
        createASNReceiveOrders: createASNReceiveOrders
      }).then(res => {
        this.receipt = false
        if (res.RetCode === '0') {
          this.Remark = ''
          this.GetPurchaseOrderInfos()
          return this.$message.success('创建成功')
        }
        return this.$message.error('错误：' + res.RetMsg)
      })
    },
    // 入库记录详情
    GetPurchaseOrderInfos() {
      this.$api.GetPurchaseOrderInfos({
        OrderPurchaseSapID: this.$route.params.OrderPurchaseSapID || 0 // this.$route.params.OrderPurchaseSapID // 4500261874//
      }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error(res.Message)
        this.form = res.Message.PurchaseOrderInfoHeader
        this.tableData = res.Message.PurchaseOrderInfoBody
        this.tempArr = res.Message.PurchaseOrderInfoBody
        this.tableTotalSize = res.Total
      })
    },
    // 查看预入库记录
    viewyuComeList(row) {
      console.log(row)
      this.yuComeList = true
      this.$api.GetASNReceiveOrderLog({
        OrderPurchaseSapID: this.form.OrderPurchaseSapID,
        PORowNum: row.PORowNum
      }).then(res => {
        this.Precord = res.Message
        console.log(this.Precord)
      })
    },
    // 查看操作记录
    viewisSetList(row) {
      this.isSetList = true
      this.$api.GetPurchareOrderOperationLog({
        OrderPurchaseSapID: this.form.OrderPurchaseSapID,
        SelectType: 1, // 0：修改采购价格记录，1：采购单明晰操作记录
        PORowNum: row.PORowNum
      }).then(res => {
        console.log(res)
        this.operationRecord = res.Message
      })
    }
  }
}
export default Index
