<template>
  <div class="order-processing">
    <PageHeader title="生成预到货通知">
      <div style="width:60%;" class="flex j-between a-center theme-color p-lr-30 jindutiao">
        <p><span class="point">1</span> 生成预到货通知</p>
        <i class="el-icon-arrow-right" />
        <p><span class="point">2</span> 仓库处理</p>
        <i class="el-icon-arrow-right" />
        <p><span class="point">3</span> 完成</p>
      </div>
    </PageHeader>
    <div class="p-lr-20 p-tb-20">
      <div class="header_div flex j-between" style="height:120px">
        <div class="left flex a-center j-center weight f12">
          采 购 信 息
        </div>
        <div class="mid">
          <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
            <div class="eachitem-eachword el-1"><span class="width_ hui">采购申请编号：</span><span class="p-left-10 no-weight">{{ form.OrderPurchaseBasicID }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">供应商名称：</span><span class="p-left-10 no-weight">{{ form.SapCompanyID ? form.SapCompanyID : form.SAPSupplierCompanyName }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">采购订单申请人：</span><span class="p-left-10 no-weight">{{ form.PurchaseManager }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">采购申请创建时间：</span><span class="p-left-10 no-weight">{{ form.PurchaseBasicWriteTime }}</span></div>
          </div>
          <div class="item min-border-bottom flex j-between a-center p-lr-10   f12">
            <div class="eachitem-eachword el-1"><span class="width_ hui">采购订单编号：</span><span class="p-left-10 no-weight">{{ form.OrderPurchaseSapID }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">来源客户名称：</span><span class="p-left-10 no-weight">{{ form.SourceOrderCompanyName }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">采购申请处理人：</span><span class="p-left-10 no-weight">{{ form.PurchaseManager }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">采购订单申请时间：</span><span class="p-left-10 no-weight">{{ form.PurchaseBasicWriteTime }}</span></div>
          </div>
          <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
            <div class="eachitem-eachword flex a-center el-1">
              <span class="hui" style="width:70px">批号要求：</span>
              {{ form.LotNoRequire !== '0' && form.LotNoRequire ? form.LotNoRequire : '' }}
            </div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">效期要求：</span><span class="p-left-10 no-weight">{{ form.ExpiredRequireShow }}</span></div>
            <div class="eachitem-eachword el-1"><span class="width_ hui">期望到货日期：</span><span class="p-left-10 no-weight">{{ form.ArrivalDate === '0001-01-01 00:00:00' ? '' : $minCommon.formatDate(new Date(form.ArrivalDate), 'yyyy-MM-dd') }}</span></div>
            <div class="eachitem-eachword  el-1">
              <span class="hui" style="width:100px">是否直送客户：</span>
              {{ form.SendType ? '是': '否' }}
            </div>
          </div>
          <div class="item min-border-bottom flex j-between a-center p-lr-10  f12">
            <div class="el-1"><span class="width_ hui">收货地址：</span><span class="p-left-10 no-weight">{{ form.SendAddress }}</span></div>
          </div>
        </div>
        <div class="right" />
      </div>
    </div>
    <div class="btns flex j-between a-center p-lr-20">
      <div class="flex">
        <el-button icon="el-icon-upload2" round size="mini" @click="OrderPurchaseExportByIDX">导出</el-button>
        <el-button round size="mini">导出PDF</el-button>
        <el-button round size="mini" icon="el-icon-printer" @click="print">打印</el-button>
        <el-button round size="mini" icon="el-icon-printer" @click="() => $refs.FieldManagement.openSetFieldManagement()">字段管理</el-button>

      </div>
      <div>
        <!-- <el-button round size="mini" @click="isHide">隐藏已完成物料</el-button> -->
        <el-button v-if="$route.params.PurchaseState !== 2 && $route.params.PurchaseState !== 4" round size="mini" @click="selectGoods">修改选中物料的价格</el-button>
        <el-button v-if="$route.params.PurchaseState !== 2 && $route.params.PurchaseState !== 4" round size="mini" @click="setSelecNumtListShow">修改选中物料的数量</el-button>
        <el-button v-if="$route.params.PurchaseState !== 2 && $route.params.PurchaseState !== 4" round size="mini" type="primary" @click="showreceipt">生成预到货通知</el-button>
      </div>
    </div>
    <div class="p-lr-20 p-tb-20">
      <el-table
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel23"
        :header-cell-style="headerCStyle"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="id"
        border
        fit
        @select-all="selectAll"
        @select="select"
      >
        <el-table-column fixed="left" type="selection" label="选择" />
        <template v-for="(item,index) in tableListJson.orderProcessing">
          <el-table-column
            v-if="item.isChecked"
            :key="index"
            width="auto"
            :label="item.label"
            :prop="item.prop"
            align="center"
            show-overflow-tooltip
          />
        </template>
        <el-table-column prop="set" label="操作" fixed="right" width="50" align="center">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="130"
              popper-class="test_pop_view"
            >
              <div class="DropDownList">
                <div class="OperationButton">
                  <el-link :underline="false" @click="viewyuComeList(scope.row)"><i class="el-icon-view p-right-10" /> 查看预入库记录</el-link>
                </div>
                <div class="OperationButton">
                  <el-link :underline="false" @click="viewisSetList(scope.row)"><i class="el-icon-download p-right-10" /> 查看操作记录</el-link>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :totel="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 预入库记录 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="800px!important" :visible.sync="yuComeList" custom-class="dielog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">预入库记录</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => yuComeList = false" />
      </div>
      <el-table
        :data="Precord"
        border
        width="100%"
        class="m-top-20"
        :header-cell-style="headerCStyle"
      >
        <el-table-column type="index" width="50" label="序号" show-overflow-tooltip />
        <el-table-column prop="ReceiveDate" label="预入库单创建时间" show-overflow-tooltip />
        <el-table-column prop="ASNID" label="预入库单号" show-overflow-tooltip />
        <el-table-column prop="OperateNum" label="预入库数量" show-overflow-tooltip />
        <el-table-column prop="StockName" label="入库仓库" show-overflow-tooltip />
        <el-table-column prop="InStockTypeShow" label="预入库单生成类型" show-overflow-tooltip />
      </el-table>
      <div class="caozuo t-right p-tb-20">
        <el-button @click="() => yuComeList = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 预入库记录 end -->
    <!-- 操作记录 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="800px" :visible.sync="isSetList" custom-class="dielog_view1" :show-close="false">
      <div class="PageHeader1 flex j-between">
        <span class="Name">查看操作记录</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => isSetList = false" />
      </div>
      <el-table
        :data="operationRecord"
        style="width: 100%;"
        border
        :show-header="false"
        :header-cell-style="headerCStyle"
      >
        <el-table-column type="index" width="50" label="序号" />
        <el-table-column prop="WriteTime" label="操作时间" show-overflow-tooltip />
        <el-table-column prop="NickName" label="操作人" show-overflow-tooltip />
        <el-table-column prop="OldVelue" label="OldVelue" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.OperTypeStr }}前:{{ scope.row.OldValue }}
          </template>
        </el-table-column>
        <el-table-column prop="NewVelue" label="NewVelue" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.OperTypeStr }}后:{{ scope.row.NewValue }}
          </template>
        </el-table-column>
        <el-table-column prop="Remark" label="操作备注" show-overflow-tooltip />
        <el-table-column prop="OperationRemark" label="备注" show-overflow-tooltip />
      </el-table>
      <div class="caozuo t-right p-tb-20 t_line_s m-top-20">
        <el-button @click="() => isSetList = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 操作记录 end -->
    <!-- 修改选中物料价格 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="800px!important" :visible.sync="setSelectList" custom-class="dielog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">修改采购订单价格</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => setSelectList = false" />
      </div>
      <div class="OrderInfoList">
        <ul>
          <li><span><label class="Name">供应商名称：</label>{{ form.SapCompanyID ? form.SapCompanyID : form.SAPSupplierCompanyName }}</span> <span><label class="Name">采购订单编号：</label> {{ form.OrderPurchaseSapID }}</span><span><label class="Name">创建时间：</label> {{ form.PurchaseBasicWriteTime }}</span></li>
          <li>
            <!-- <div>含税金额：{{ form.TaxAmount }}</div> -->
            <span><label class="Name">不含税总金额：</label>{{ Allprice }}</span>
            <span><label class="Name">含税总金额：</label>{{ AllToaprice }}</span>
            <!-- <div>批量调整单价<span style="color:red">(不含税 正值加 负值减 )：</span></div> -->
            <!-- <el-input v-model="price" size="mini" style="width:80px" /> -->
            <!-- <el-input-number v-model="price" :controls="false" size="mini" /> -->
            <span><label class="Name">调价后不含税总金额：</label>{{ afetrAllprice }}</span>
            <span><label class="Name">调价后含税总金额：</label>{{ afterAllToaprice }}</span>
            <!-- ALLtoel -->
          </li>
          <li><label class="Name">调价历史：</label><span v-html="reoson" /></li>
        </ul>
      </div>

      <el-table
        :data="selectedMaterial"
        style="width: 100%;"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column type="index" width="45" label="序号" />
        <el-table-column prop="InvCode" label="物料编号" elign="center" show-overflow-tooltip width="120" />
        <el-table-column prop="ManuPartNum" label="原厂编号" elign="center" show-overflow-tooltip width="100" />
        <el-table-column prop="InvName" label="物料名称" elign="center" show-overflow-tooltip width="200" />
        <el-table-column prop="BrandName" label="品牌" elign="center" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格型号" elign="center" show-overflow-tooltip width="200" />
        <el-table-column prop="StockUnitN" label="单位" elign="center" show-overflow-tooltip />
        <el-table-column prop="PurchaseSapNum" label="数量" elign="center" show-overflow-tooltip />
        <!-- <el-table-column prop="NetPrice" label="含税单价" show-overflow-tooltip /> -->
        <el-table-column prop="PurchaseSapPrice" label="不含税单价" show-overflow-tooltip width="100" />
        <el-table-column prop="TaxRate" label="税率" show-overflow-tooltip />
        <el-table-column prop="TaxAmount" label="含税金额" show-overflow-tooltip width="100" />
        <el-table-column prop="Amount" label="不含税金额" show-overflow-tooltip width="100" />
        <el-table-column prop="set" label="调价后不含税单价" width="120" fixed="right">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.price" :controls="false" size="mini" style="width:100px" :min="1" />
            <!-- <el-input v-model="scope.row.price" size="mini" /> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="m-tb-30" />
      <div class="caozuo p-tb-20 t_line_s" style="display:inline-block">
        <div style="width:70%;float:left;text-align: left;">
          <span>调价原因：</span>
          <el-input v-model="tRemake" style="width:300px" />
        </div>
        <div style="width:30%;float:right;">
          <el-button @click="() => setSelectList = false">关闭</el-button>
          <el-button type="primary" @click="tSubmit">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 修改选中物料价格 end -->
    <!-- 修改选中物料数量 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="80%" :visible.sync="setSelecNumtList" custom-class="dielog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">修改采购订单数量</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => setSelecNumtList = false" />
      </div>
      <div class="p-tb-10">
        <span class="m-right-20"><span class="hui">供应商名称：</span>{{ form.SapCompanyID ? form.SapCompanyID : form.SAPSupplierCompanyName }}</span>
        <span class="m-right-20"><span class="hui">采购订单编号：</span>{{ form.OrderPurchaseSapID }}</span>
        <span class="m-right-20"><span class="hui">创建时间：</span>{{ form.PurchaseBasicWriteTime }}</span>
      </div>
      <el-table
        :data="selectedMaterial"
        style="width: 100%;"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column type="index" width="45" label="序号" elign="center" show-overflow-tooltip />
        <el-table-column prop="InvCode" label="物料编号" elign="center" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" elign="center" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" elign="center" show-overflow-tooltip width="200" />
        <el-table-column prop="BrandName" label="品牌" elign="center" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格型号" elign="center" show-overflow-tooltip width="200" />
        <el-table-column prop="StockUnitN" label="单位" elign="center" show-overflow-tooltip />
        <el-table-column prop="PurchaseSapNum" label="数量" elign="center" show-overflow-tooltip />
        <el-table-column prop="Specification" label="调整后数量" elign="center" width="85">
          <template slot-scope="scope">
            <!-- <el-input v-model="scope.row.num" size="mini" /> -->
            <el-input-number v-model="scope.row.num" :controls="false" size="mini" style="width:100px" :min="scope.row.ReceiveNum" :max="scope.row.PurchaseSapNum*1" />
          </template>
        </el-table-column>
        <el-table-column prop="Amount" label="金额" elign="center" show-overflow-tooltip />
        <el-table-column prop="NetPrice" label="含税单价" elign="center" show-overflow-tooltip />
        <el-table-column prop="ReceiveNum" label="入库数量" elign="center" show-overflow-tooltip />
        <el-table-column prop="ReceiveDate" label="入库时间" elign="center" show-overflow-tooltip />
      </el-table>
      <div class="caozuo p-tb-20" style="display:inline-block; width:100%">
        <div style="width:70%;float:left;text-align:left;">
          <span style="color:red">*</span>删除原因：
          <el-input v-model="delRemake" style="width:30%" />
        </div>
        <div style="width:30%;float:right">
          <el-button @click="() => setSelecNumtList = false">关闭</el-button>
          <el-button type="primary" @click="setSelecNumtListSubmit">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 修改选中物料数量 end -->
    <!-- 生成预入库单 start -->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" width="80%" :visible.sync="receipt" custom-class="dielog_view1" :show-close="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">预到货通知</span>
        <i class="Close el-icon-close hui" @click="() => receipt = false" />
      </div>
      <p class="f12 weight">供应商名称：{{ form.SapCompanyID ? form.SapCompanyID : form.SAPSupplierCompanyName }} <span class="p-lr-10">采购订单编号: {{ form.OrderPurchaseSapID }}</span><span>创建时间: {{ form.PurchaseBasicWriteTime }}</span></p>
      <el-table
        :data="selectedMaterial"
        border
        :header-cell-style="headerCStyle"
      >
        <el-table-column prop="OrderPurchaseSapID" label="采购订单编号" align="left" show-overflow-tooltip />
        <el-table-column prop="ManuPartNum" label="原厂编号" align="left" show-overflow-tooltip />
        <el-table-column prop="InvName" label="物料名称" align="left" show-overflow-tooltip />
        <el-table-column prop="BrandName" label="品牌" align="left" show-overflow-tooltip />
        <el-table-column prop="Specification" label="规格" align="left" show-overflow-tooltip />
        <el-table-column prop="StockUnitN" label="单位" align="left" show-overflow-tooltip />
        <el-table-column prop="aaa" label="未处理数量" align="left">
          <template slot-scope="scope">
            {{ (scope.row.PurchaseSapNum - scope.row.ReceiveNum) }}
          </template>
        </el-table-column>
        <el-table-column prop="PreWarehousingNum" label="预入库数量" align="left">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.PreWarehousingNum" :controls="false" size="mini" style="width:100px" :min="1" :max="(scope.row.PurchaseSapNum - scope.row.ReceiveNum)" />
          </template>
        </el-table-column>
        <el-table-column prop="LotNo" label="批号" align="left">
          <template slot-scope="scope">
            <el-input v-model="scope.row.LotNo" size="mini" />
          </template>
        </el-table-column>
        <af-table-column prop="Expiredate" label="效期" align="left">
          <template slot-scope="scope">
            <!-- <el-input v-model="scope.row.Expiredate" size="mini" /> -->
            <el-date-picker
              v-model="scope.row.Expiredate"
              type="date"
              placeholder="选择日期"
              size="mini"
              value-format="yyyy-MM-dd"
            />
          </template>
        </af-table-column>
        <el-table-column prop="StockID" label="库存地点" align="left">
          <template slot-scope="scope">
            <!-- <el-input v-model="scope.row.StockID" size="mini" /> -->
            <el-select
              v-model="scope.row.StockID"
              filterable
              placeholder="请选择"
              size="mini"
              @change="isChangeStock"
            >
              <el-option v-for="item in Cklist" :key="item.IDX" :label="item.StockName" :value="item.IDX" />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <div class="caozuotiao p-tb-20">
        <div class="left">
          <label><span v-if="isDefaultStock" style="color:red">*</span> 备注：</label>
          <el-input v-model="remake" />
        </div>
        <div class="right">
          <el-button @click="() => receipt = false">关闭</el-button>
          <el-button type="primary" @click="CreateASNReceiveOrder">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 生成预入库单 end -->
    <!-- 打印交货单 start-->
    <el-dialog :close-on-click-modal="false" class="DepartmentBox" title="打印预览" center custom-class="dielog_view1" :visible.sync="printDeliveryVisible" width="80%" :close-on-click-model="false">
      <div id="printDeliveryDom" class="pritInfo">
        <div style="width: 100%">
          <el-table
            id="testTable"
            :data="pritInfo.tableList"
            :cell-style="cellstyle"
            border
            style="width: 100%"
          >
            <el-table-column prop="InvCode" label="物料编号" show-overflow-tooltip width="110" align="left" />
            <el-table-column prop="ManuPartNum" label="原厂编号" show-overflow-tooltip width="110" />
            <el-table-column prop="InvName" label="物料名称" show-overflow-tooltip width="200" />
            <el-table-column prop="BrandName" label="品牌" show-overflow-tooltip width="200" />
            <el-table-column prop="Specification" label="规格型号" show-overflow-tooltip width="200" />
            <el-table-column prop="StockUnitN" label="单位" show-overflow-tooltip />
            <el-table-column prop="PurchaseSapNum" label="数量" show-overflow-tooltip />
            <el-table-column prop="NetPrice" label="单价" show-overflow-tooltip />
            <el-table-column prop="Amount" label="金额" show-overflow-tooltip />
            <el-table-column prop="TaxAmount" label="含税金额" show-overflow-tooltip />
            <el-table-column prop="StockID" label="仓位" show-overflow-tooltip />
            <el-table-column prop="ReceiveNum" label="入库数量" show-overflow-tooltip />
            <el-table-column prop="ReceiveDate" label="入库时间" show-overflow-tooltip />
            <el-table-column prop="ASNDNum" label="已入库数量" show-overflow-tooltip width="90" />
          </el-table>
        </div>
        <div slot="footer" class="caozuo p-tb-20">
          <el-button @click="printDeliveryVisible = false">取 消</el-button>
          <el-button type="primary" @click="printDelivery">确认打印</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 打印交货单 end-->
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetPurchaseOrderInfos" :list="tableListJson.orderProcessing" name="orderProcessing" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import Index from '@/minxin/purchasing-management/order-processing'
export default Index
</script>
<style lang='scss' scoped>
@import "@/style/purchase.scss";
</style>
